import de from './locales/de.json'
import en from './locales/en.json'
// import zh from './locales/zh.json'

export default {
  legacy: false,
  fallbackLocale: 'de',
  messages: {
    de,
    en,
  },
}
